// @flow
import React, {useState, useEffect, useRef, useMemo} from 'react';
import {Button, Spinner} from 'react-bootstrap';
import {Link, useLocation} from 'react-router-dom';
import excel from 'shared/Excel';
import {series} from 'async';
import {cloneDeep, intersectionWith, isNull, mergeWith} from 'lodash';
import {parseString} from 'xml2js';
import {useTheme} from 'styled-components';
import {MATERIAL_TYPE} from 'components/customer/Materials/helper/constants';

type IconType = {
    iconName: string,
    disabled?: boolean,
    onClick?: Function,
    noLogin?: boolean,
    style?: {},
    color?: 'primary' | 'secondary' | string,
    className?: string,
    localDirectory?: boolean,
    title?: String,
};

export const Icon = ({
    iconName,
    disabled = false,
    onClick = () => {},
    noLogin = false,
    color = '',
    localDirectory = true,
    ...props
}: IconType) => {
    const theme = useTheme();
    const primaryColour = theme.colors.primaryIconColor.main;
    const secondaryColour = theme.colors.secondaryIconColor.main;

    try {
        if (typeof iconName !== 'undefined' && iconName !== '') {
            // $FlowIgnore: We need to dynamically fetch images
            /* eslint-disable security/detect-non-literal-require */
            let svg = localDirectory
                ? require('../assets/images/icons/' + iconName)
                : '';

            if (!noLogin && localDirectory) {
                if (color.length > 0) {
                    const replacingColor = color;

                    if (replacingColor == 'primary') {
                        replacingColor = primaryColour;
                    } else if (replacingColor == 'secondary') {
                        replacingColor = secondaryColour;
                    }

                    svg = svg.replace(/#1392CD/gi, replacingColor);
                    svg = svg.replace(/#204380/gi, replacingColor);
                } else {
                    if (secondaryColour)
                        svg = svg.replace(/#1392CD/gi, secondaryColour);

                    if (primaryColour)
                        svg = svg.replace(/#204380/gi, primaryColour);
                }
            }

            const imageBase64: string = btoa(svg);

            return (
                // $FlowIgnore
                <img
                    src={
                        localDirectory
                            ? `data:image/svg+xml;base64, ${imageBase64}`
                            : iconName
                    }
                    alt={iconName}
                    style={disabled ? {opacity: 0} : {}}
                    onClick={!disabled ? onClick : undefined}
                    {...props}
                />
            );
        } else {
            return null;
        }
    } catch (e) {
        return null;
    }
};

/**
 *
 *
 * @param {string} image
 * @param {string} oldColor
 * @param {string} newColor
 * @return {*}  {string}
 */
export const getColoredImage = (
    image: string,
    oldColor: string,
    newColor: string
): string => {
    if (typeof image !== 'undefined' && image !== '') {
        // $FlowIgnore
        image = image.replaceAll(oldColor, newColor);

        const imageBase64: string = btoa(image);

        return `data:image/svg+xml;base64, ${imageBase64}`;
    } else return image;
};

type TCalculateTotalDoors = {
    door_count: number,
    door_hang: string,
};

/**
 * Calculates the total number of doors based on door count and door hang type.
 *
 * @param {TCalculateTotalDoors} params - The parameters for the calculation.
 * @param {number} params.door_count - doors value of cabinet in database
 * @param {string} params.door_hang - door_hang (e.g. Pair, Bifold)
 * @returns {number} The total number of doors. Returns 0 if door_count is 0,
 * 2 if door_count is 1 and door_hang is "Pair" or includes "bifold",
 * 2 if door_count is 2 and door_hang does not include "^", otherwise returns 1.
 */
export const calculateTotalDoors = ({
    door_count,
    door_hang,
}: TCalculateTotalDoors): number => {
    return door_count === 0
        ? 0
        : (door_count === 1 &&
              (door_hang === 'Pair' || door_hang.includes('bifold'))) ||
          (door_count === 2 && !door_hang.includes('^'))
        ? 2
        : 1;
};

type CBCButtonProps = React.AllHTMLAttributes<HTMLButtonElement> & {
    onClick?: Function,
    children?: React$Element<any> | string,
    iconName?: string,
    className?: string,
    asLink?: boolean,
    to?: string,
    type?: string,
    target?: string,
    disabled?: boolean,
    style?: object,
};
/**
 * @params {Object} obj Object with all the info requried to make a button
 * @params {event} obj.onClick click event for this button
 * @params {*} obj.children button contents
 * @params {string} obj.iconName name of icon to be placed inside button
 * @params {string} obj.className Optional, className of button button-blue|button-light, default is blue
 * @params {boolean} obj.asLink Optional, true to use a html tag instead of button
 * @params {boolean} obj.to Optional, If obj.asLink is true provide path for the a tag
 * @params {string} obj.type Optional, Provide how the button needs to be rendered
 *
 * @return {Button|Link} react-bootstrap button with custom css
 *
 */
export const CBCButton = ({
    onClick,
    children,
    iconName,
    className = 'button-blue',
    asLink = false,
    to = '',
    type = 'button',
    target = '_self',
    ...props
}: CBCButtonProps): React$Element<any> => {
    const icon = useMemo<React$Element<any>>(() => {
        if (typeof iconName !== 'undefined' && iconName !== '') {
            return <Icon iconName={iconName} />;
        }

        return <></>;
    }, [iconName]);

    if (asLink) {
        return (
            // $FlowIgnore
            <Link
                to={to}
                className={'btn btn-link ' + className}
                target={target}
                {...props}>
                {icon}
                <span>{children}</span>
            </Link>
        );
    } else {
        return (
            // $FlowIgnore
            <Button
                variant="link"
                onClick={onClick}
                className={className}
                type={type}
                {...props}>
                {icon}
                <span>{children}</span>
            </Button>
        );
    }
};

type LoaderProps = {
    loader: boolean,
    hideInitially?: boolean,
    relative?: boolean,
    children?: any,
    loadingText?: React$Element<any>,
    smallPaddingTop?: boolean,
};
/**
 *
 *
 * @param {LoaderProps} {
 *     loader,
 *     hideInitially = false,
 *     relative = false,
 *     children,
 * }
 * @return {*}  {React$Element<any>}
 */
export const Loader = ({
    loader,
    hideInitially = false,
    relative = false,
    children,
    loadingText = <></>,
    smallPaddingTop = false,
    className = '',
}: LoaderProps): React$Element<any> => {
    let localClassName = `${className} loading-screen`;
    if (relative) {
        localClassName = `${className} loading-screen loading-absolute`;

        if (smallPaddingTop) localClassName += ' pt-small';
    }

    if (loader) {
        return (
            <>
                {typeof children !== 'undefined' && !hideInitially
                    ? children
                    : null}
                <section
                    className={localClassName}
                    style={hideInitially ? {background: 'white'} : {}}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    {loadingText}
                </section>
            </>
        );
    } else {
        return children ? children : <></>;
    }
};

/**
 *
 *
 * @param {Object} source
 * @param {Object} obj
 * @return {*}
 */
export const compareObjects = (source: Object, obj: Object): boolean => {
    return (
        Object.keys(source).every((key) => {
            return obj.hasOwnProperty(key) && source[key] === obj[key];
        }) &&
        Object.keys(obj).every((key) => {
            return source.hasOwnProperty(key) && source[key] === obj[key];
        })
    );
};

type PriceDataProps = {
    currencyType?: string,
};
/**
 *
 *
 * @param {number} value
 * @param {PriceDataProps} data
 * @return {*}  {string}
 */
export const formatPrice = (value: number, data: PriceDataProps): string => {
    return (
        (typeof data.currencyType !== 'undefined'
            ? `${data.currencyType}`
            : '$') + numberFormat(parseFloat(value).toFixed(2))
    );
};

/**
 *
 *
 * @param {number} value
 * @return {*}
 */
export const numberFormat = (value: string): string => {
    if (isNaN(value)) {
        value = '0';
    }

    return parseFloat(value).toLocaleString('en', {minimumFractionDigits: 2});
};

/**
 *
 * @param {number} length Target length of output string
 * @param {boolean} password
 * @param {string} chars
 *
 * @return {string} Random alpha numeric string of length
 */
export const randomString = (length = 5, password = false, chars = '!aA#') => {
    if (password) {
        let mask = '';
        let regex = '';
        if (chars.indexOf('a') > -1) {
            mask += 'abcdefghijkmnopqrstuvwxyz';
            regex += '(?=.*[a-z])';
        }
        if (chars.indexOf('!') > -1) {
            mask += '@$!*?&';
            regex += '(?=.*[@$!*?&])';
        }
        if (chars.indexOf('A') > -1) {
            mask += 'ABCDEFGHJKLMNPQRSTUVWXYZ';
            regex += '(?=.*[A-Z])';
        }
        if (chars.indexOf('#') > -1) {
            mask += '123456789';
            regex += '(?=.*[0-9])';
        }
        if (chars.indexOf('-') > -1) {
            mask += '-';
            regex += '(?=.*[-])(?=[^-])(?=.*[^-]$)';
        }
        let result = '';
        for (let i = length; i > 0; --i)
            result += mask[Math.floor(Math.random() * mask.length)];

        const rule = `^${regex}([a-zA-Z0-9@$!*?&-]{8,})$`;
        if (new RegExp(rule, 'gm').test(result)) return result;

        return randomString(length, password, chars);
    }

    return Math.round(
        Math.pow(36, length + 1) - Math.random() * Math.pow(36, length) - 1
    )
        .toString(36)
        .slice(1);
};

/**
 *
 * @param {Array} data Object to scan
 * @param {string|boolean} field field to retrieve value for
 * @param {string} referenceField reference field name
 * @param {string|number} referenceValue reference field value
 *
 * @returns {string|number|boolean} returns the value of the field provided
 */
export const getValueByRef = (
    data,
    field = false,
    referenceField,
    referenceValue
) => {
    if (!Array.isArray(data)) return false;

    let row = data.filter((row) => {
        return (
            row.hasOwnProperty(referenceField) &&
            row[referenceField] === referenceValue
        );
    });

    if (field)
        return row.length && row[0].hasOwnProperty(field)
            ? row[0][field]
            : false;
    else return row.length && row[0];
};

/**
 *
 * @param notify
 * @param messages
 * @param type
 * @param toast
 * @param duration
 */
export const genericMessageHandler = (
    notify,
    messages,
    type = 'danger',
    toast = true,
    duration = 3000
) => {
    if (Array.isArray(messages)) {
        notify(
            messages.map((message) => {
                return {variant: type, message: message, toast};
            }),
            duration
        );
    } else {
        notify([{variant: type, message: messages.message, toast}], duration);
    }
    // console.trace(messages); //remove later, just for debugging
};

export const urlPartsJob = (): Array<string> => {
    const location = useLocation();
    const [jobAndRoom, setJobAndRoom] = useState<Array<string>>([]);

    useEffect(() => {
        const jobAndRoomIds = [];
        const parts = location.pathname.split('/');

        parts.forEach((part, index) => {
            if (['job', 'room'].includes(part)) {
                if (typeof parts[index + 1] !== 'undefined')
                    jobAndRoomIds.push(parts[index + 1]);
            }
        });

        setJobAndRoom(jobAndRoomIds);
    }, [location]);

    return jobAndRoom;
};

export const pageName = (location) => {
    location = location
        .split('/')
        .filter((part) => isNaN(part) && part !== 'v2');

    return location.length ? location[0] : '';
};

/**
 *
 * Use this hook to deconstruct the search query.
 *
 * For example: ?foo=1&bar=2
 *
 * You can use `const { foo, bar } = useQueryParams();` to get the values of the search query
 *
 * */

export const useQueryParams = () => {
    const location = useLocation();

    if (location.search.length) {
        let search = location.search.substring(1);

        search = decodeURI(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"');

        if (search.indexOf('copy') >= -1)
            search = search.replace('copy', 'copy":"true');

        search = JSON.parse('{"' + search + '"}');

        return search;
    }

    return {};
};

export const usePromise = (
    callback,
    promises,
    errorFunc,
    cancel,
    debug = false,
    allowError = false
) => {
    const isCancelled = {value: false};

    // Expects promises to be an object if not an array
    const keys = Array.isArray(promises) ? [] : Object.keys(promises);
    promises = Array.isArray(promises) ? promises : Object.values(promises);
    promises = promises.map((promise, index) => {
        return (cb) => {
            if (!isCancelled.value) {
                Promise.resolve(promise).then(
                    (response) => {
                        debug &&
                            console.log(
                                `${new Date().toLocaleTimeString()} - Resolving promise #${
                                    index + 1
                                } with response ${JSON.stringify(response)}`
                            );
                        cb(null, response);
                    },
                    (error) => {
                        cb(error);
                    }
                );
            } else {
                if (debug) {
                    cb(
                        new Error(
                            `Component unmounted before operation ${
                                index + 1
                            } was completed.`
                        )
                    );
                }
            }
        };
    });

    series(promises, (error, results) => {
        try {
            if (error) {
                // NOTE: unwrap errorFunc(error, isCancelled); call from this
                // if statement when proper session handling is done
                if (
                    !(
                        error.response &&
                        (error.response.status === 400 ||
                            error.response.status === 401)
                    )
                ) {
                    errorFunc(error, isCancelled);
                }

                if (!allowError) {
                    return;
                }
            }

            if (isCancelled.value) return;

            if (keys.length) {
                const objectResponse = {};
                keys.forEach((key, index) => {
                    objectResponse[key] = results[index];
                });

                callback(objectResponse);
            } else callback(results);
        } catch (e) {
            errorFunc(e, isCancelled);
        }
    });

    return () => {
        isCancelled.value = true;
        cancel && cancel();
    };
};

export const jobId = (jobId) => {
    return parseInt(jobId) + 10000;
};

export const productFilter = (
    products: any[],
    checkFavourites: boolean,
    checkCategories: any[],
    checkSubCategories: any[],
    text: string
): any[] => {
    const checkText = text.length;
    return products.filter((quickProduct) => {
        const textSearchArray = text.toLowerCase().split(' ');
        const needle = quickProduct.changed_name
            ? intersectionWith(
                  textSearchArray,
                  quickProduct.changed_name.toLowerCase().split(' '),
                  (needle, hayStack) => hayStack.indexOf(needle) > -1
              ).length == textSearchArray.length ||
              intersectionWith(
                  textSearchArray,
                  quickProduct.text.toLowerCase().split(' '),
                  (needle, hayStack) => hayStack.indexOf(needle) > -1
              ).length == textSearchArray.length
            : intersectionWith(
                  textSearchArray,
                  quickProduct.text.toLowerCase().split(' '),
                  (needle, hayStack) => hayStack.indexOf(needle) > -1
              ).length == textSearchArray.length;
        return (
            (checkText
                ? (text.toLowerCase() === 'rail' &&
                      quickProduct.isRecessedRail == 1) ||
                  (text.toLowerCase() === 'door' && quickProduct.doors > 0) ||
                  (text.toLowerCase() === 'doors' && quickProduct.doors > 1) ||
                  needle
                : true) &&
            (checkFavourites ? parseInt(quickProduct.favourites) > 0 : true) &&
            (checkCategories.length
                ? checkCategories.includes(quickProduct.style)
                : true) &&
            (checkSubCategories.length
                ? checkSubCategories.filter((subCategory) => {
                      if (isNaN(subCategory))
                          return (
                              quickProduct.text
                                  .toLowerCase()
                                  .indexOf(subCategory.toLowerCase()) > -1
                          );
                      else return quickProduct.subStyleId === subCategory;
                  }).length
                : true)
        );
    });
};

export const localeDateString = (date) => {
    return new Date(date).toLocaleDateString();
};

export const toFixed = (num, fixed) => {
    fixed = Math.pow(10, fixed);

    return parseInt((num * fixed).toFixed(1)) / fixed;
};

export const formatStringWithFields = (
    string: string,
    scope: any,
    measurementUnit: string = 'mm'
): string => {
    try {
        const regExp = /\{([^}]+)\}/g;
        const matches = string.match(regExp);

        if (!matches) {
            return string;
        }

        matches.forEach((formula) => {
            const hasMetric = formula.indexOf(':size') > -1;

            let reGex = /[{]|[}]/g;
            if (hasMetric) {
                reGex = /[{]|[}]|(:size)/g;
            }

            const onlyFormula = formula.replace(reGex, '');

            let value = excel.calculate(onlyFormula, scope);

            if (hasMetric) {
                value = `${value} ${measurementUnit}`;
            }

            string = string.replace(formula, value);
        });

        return string;
    } catch (e) {
        // console.log(string);
        // console.log(e);
    }
};

export const getFieldValue = (
    fieldValue: any,
    originalScope: any,
    userProfile = {}
): string => {
    if (typeof fieldValue != 'string') {
        return fieldValue;
    }
    // cloning as scope can be read only object
    const scope = originalScope ? cloneDeep(originalScope) : {};

    if (!scope.hasOwnProperty('ext_material_thickness')) {
        scope.ext_material_thickness = 16.5; // replace with true value later
    }

    if (!scope.hasOwnProperty('fieldset')) {
        scope.fieldset = {index: 0}; // Only for qfp as there is no complex horizontal/vertical rail positions
    }

    if (!scope.hasOwnProperty('rangehood') || !scope.rangehood) {
        scope.rangehood = '';
    }

    try {
        const output = formatStringWithFields(
            fieldValue,
            scope,
            userProfile?.defaultMeasurementUnit === 'METRIC' ? 'mm' : 'inches'
        );

        if (output == fieldValue) {
            // If we didn't substitute anything, it must be a formula
            return excel.calculate(fieldValue, scope);
        }

        return output;
    } catch (e) {
        return fieldValue;
    }
};

export const hasOption = (field, option) => {
    return (
        field.hasOwnProperty('options') && field.options.hasOwnProperty(option)
    );
};

export const isOptionBoolean = (field, option) => {
    return typeof field.options[option] === 'boolean';
};

export const isFieldEnabled = (field, values, materialOptions) => {
    const scope = cloneDeep({...values, ...materialOptions});
    if (scope.hasOwnProperty('fingerPull_styles')) {
        scope.fingerPull_styles =
            scope.fingerPull_styles != null && scope.fingerPull_styles >= 0;
    }

    return excel.calculate(field.options.enabled, scope);
};

export const hexToRgb = (hex: string): string => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
              result[3],
              16
          )}`
        : hex;
};

export const sumArrayVal = (array_, index) => {
    if (index < 0) {
        return 0;
    }

    if (index >= array_.length) {
        index = array_.length - 1;
    }

    return array_[index] + sumArrayVal(array_, index - 1);
};

export const pdfOptionsOrder = (PDFMenu, option, userProfile) => {
    if (userProfile.defaultJobPropertiesPdf === 2) {
        return option === PDFMenu.JOB_DETAILS_PDF ? 1 : 3;
    } else {
        return option === PDFMenu.JOB_DETAILS_PDF ? 3 : 1;
    }
};

export const getPanelValidation = (productAttributes) => {
    return {
        max_depth: productAttributes.max_depth,
        max_height: productAttributes.max_height,
        max_width: productAttributes.max_width,
        min_depth: productAttributes.min_depth,
        min_height: productAttributes.min_height,
        min_width: productAttributes.min_width,
    };
};

const getMaxForProduct = (productAttributes) => {
    if (!productAttributes) {
        return {};
    }
    const MAT_TYPE_BOTH = 0;
    const MAT_TYPE_CARCASE = 2;
    let [
        extMaxLength,
        extMaxWidth,
        carcMaxLength,
        carcMaxWidth,
        maxHeight,
        maxWidth,
        maxDepth,
    ] = Array(6).fill(-1);

    let [
        maxHeight2,
        maxWidth2,
        extGrain,
        carcGrain,
        extGrainSelected,
        carcGrainSelected,
        grain,
        grainSelected,
    ] = Array(8).fill(0);

    if (productAttributes.ext_material) {
        extMaxLength = productAttributes.ext_material.changed_length
            ? productAttributes.ext_material.changed_length
            : productAttributes.ext_material.default_length;
        extMaxWidth = productAttributes.ext_material.changed_width
            ? productAttributes.ext_material.changed_width
            : productAttributes.ext_material.default_width;
        extGrain = productAttributes.ext_material.hor_grain;
        extGrainSelected = productAttributes.ext_hor_grain;
    }

    if (productAttributes.carc_material) {
        carcMaxLength = productAttributes.carc_material.changed_length
            ? productAttributes.carc_material.changed_length
            : productAttributes.carc_material.default_length;
        carcMaxWidth = productAttributes.carc_material.changed_width
            ? productAttributes.carc_material.changed_width
            : productAttributes.carc_material.default_width;
        carcGrain = productAttributes.carc_material.hor_grain;
        carcGrainSelected = productAttributes.carc_hor_grain;
    }

    //if both or carcase, use carcase mat length and width
    let cabinet = productAttributes.cabinet.attributes;
    if (
        cabinet.mat_type == MAT_TYPE_BOTH ||
        cabinet.mat_type == MAT_TYPE_CARCASE
    ) {
        maxHeight = carcMaxLength;
        maxWidth = carcMaxWidth;
        maxDepth = carcMaxWidth;
        grain = carcGrain;
        grainSelected = carcGrainSelected;

        maxHeight2 = extMaxLength;
        maxWidth2 = extMaxWidth;
    } else {
        maxHeight = extMaxLength;
        maxWidth = extMaxWidth;
        maxDepth = extMaxWidth;
        grain = extGrain;
        grainSelected = extGrainSelected;
    }

    //kickers have width/height swapped
    if (
        ['kick length', 'kicklength', 'bulkhead'].indexOf(
            cabinet.description.toLowerCase()
        ) >= 0 ||
        cabinet.original_name.toLowerCase().indexOf('kick length') >= 0 ||
        cabinet.original_name.toLowerCase().indexOf('kicklength') >= 0 ||
        cabinet.original_name.toLowerCase().indexOf('kicker framed') >= 0
    ) {
        if (cabinet.max_height != 0 && cabinet.max_height < maxWidth) {
            maxWidth = cabinet.max_height;
        }
        if (cabinet.max_width != 0 && cabinet.max_width < maxHeight) {
            maxHeight = cabinet.max_width;
        }

        //If we're using a brushed stainless or aluminium kicker we want the limits to come from the material not from the product.
        if (cabinet.hasOwnProperty('cabinetProperties')) {
            if (
                cabinet.cabinetProperties.hasOwnProperty(
                    'ONLY_BRUSHED_ALUMINIUM_COLOUR'
                ) ||
                cabinet.cabinetProperties.hasOwnProperty(
                    'ONLY_BRUSHED_STAINLESS_COLOUR'
                )
            ) {
                if (
                    cabinet.cabinetProperties.hasOwnProperty(
                        'GET_MAX_HEIGHT_FROM_COLOUR_MATERIAL'
                    )
                ) {
                    maxHeight = productAttributes.ext_material.changed_length
                        ? productAttributes.ext_material.changed_length
                        : productAttributes.ext_material.default_length;
                }
                if (
                    cabinet.cabinetProperties.hasOwnProperty(
                        'GET_MAX_WIDTH_FROM_COLOUR_MATERIAL'
                    )
                ) {
                    maxWidth = productAttributes.ext_material.changed_width
                        ? productAttributes.ext_material.changed_width
                        : productAttributes.ext_material.default_width;
                }
            }
        }

        //normal cabinets
    } else {
        if (cabinet.max_height != 0 && cabinet.max_height < maxHeight) {
            maxHeight = cabinet.max_height;
        }
        if (cabinet.max_width != 0 && cabinet.max_width < maxWidth) {
            maxWidth = cabinet.max_width;
        }
    }

    if (cabinet.max_depth != 0 && cabinet.max_depth < maxDepth) {
        maxDepth = cabinet.max_depth;
    }

    return {
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        maxDepth: maxDepth,
        grain: grain,
        grainSelected: grainSelected,
        maxHeight2: maxHeight2,
        maxWidth2: maxWidth2,
    };
};

export const productMaxDimensions = (
    product,
    fieldValue,
    userProfile,
    scope
) => {
    const {
        maxHeight,
        maxWidth,
        maxDepth,
        maxWidth2,
        maxHeight2,
        grainSelected,
        grain,
    } = getMaxForProduct(product);
    let [maxHeightText, maxDepthText, maxWidthText, rangeHoodText] =
        Array(3).fill('');
    const unit =
        userProfile.defaultMeasurementUnit === 'METRIC' ? 'mm' : 'inches';
    if (grain != 1) {
        maxHeightText = `${
            isNull(maxHeight) ? maxHeight2 : maxHeight
        }${unit} x ${isNull(maxWidth) ? maxWidth2 : maxWidth}`;
        maxWidthText = `${
            isNull(maxHeight) ? maxHeight2 : maxHeight
        }${unit} x ${isNull(maxWidth) ? maxWidth2 : maxWidth}`;
        maxDepthText = `${
            isNull(maxHeight) ? maxHeight2 : maxHeight
        }${unit} x ${isNull(maxWidth) ? maxWidth2 : maxWidth}`;
    } else {
        // If grained material.
        if (grainSelected == 1) {
            // If user selected horizontal grain.
            // height = width and width = height and depth = height
            maxHeightText = isNull(maxWidth) ? maxWidth : maxWidth2;
            maxWidthText = isNull(maxHeight) ? maxHeight2 : maxHeight;
            maxDepthText = isNull(maxHeight) ? maxHeight2 : maxHeight;
        } else {
            // If user selected vertical grain.
            // height = height and width = width and depth = width
            maxHeightText = isNull(maxHeight) ? maxHeight2 : maxHeight;
            maxWidthText = isNull(maxWidth) ? maxWidth : maxWidth2;
            maxDepthText = isNull(maxWidth) ? maxWidth : maxWidth2;
        }
    }

    const regExp = /\{([^}]+)\}/g;
    const matches = fieldValue.match(regExp);
    let cabinet = product.cabinet.attributes;

    if (matches) {
        matches.forEach((formula) => {
            let value = '';
            const hasMetric = formula.indexOf(':size') > -1;

            let reGex = /[{]|[}]/g;
            if (hasMetric) {
                reGex = /[{]|[}]|(:size)/g;
            }

            let onlyFormula = formula.replace(reGex, '');
            switch (onlyFormula) {
                case '$rangehood':
                case '$range_hood':
                    if (scope && scope.rangehood == 1) {
                        value = `Includes height of Rangehood.`;
                    }
                    if (scope && scope.rangehood == 0) {
                        value = '';
                    }
                    value = fieldValue.replace(formula, value);
                    break;
                case '$min_height':
                    value = fieldValue.replace(formula, cabinet.min_height);
                    break;
                case '$min_depth':
                    value = fieldValue.replace(formula, cabinet.min_depth);
                    break;
                case '$min_width':
                    value = fieldValue.replace(formula, cabinet.min_width);
                    break;
                case '$max_depth':
                    value = fieldValue.replace(formula, maxDepthText);
                    break;
                case '$max_width':
                    value = fieldValue.replace(formula, maxWidthText);
                    break;
                case '$max_height':
                    value = fieldValue.replace(formula, maxHeightText);
                    break;
                default:
                    value = fieldValue.replace(formula, '');
            }

            if (hasMetric) {
                value = `${value} ${unit}`;
            }

            fieldValue = value;
        });
    }

    return fieldValue;
};
export const validSubpanels = (subPanels) => {
    const updatedSubPanels = JSON.parse(JSON.stringify(subPanels));

    if (updatedSubPanels.length == 3 && updatedSubPanels[0].length == 3) {
        return updatedSubPanels;
    }

    if (updatedSubPanels.length == 1) {
        updatedSubPanels.splice(0, 0, Array(3).fill(false));
        updatedSubPanels.splice(2, 0, Array(3).fill(false));
    } else if (updatedSubPanels.length == 2) {
        updatedSubPanels.splice(1, 0, Array(3).fill(false));
    }

    updatedSubPanels.forEach((position) => {
        if (position.length == 2) {
            position.splice(1, 0, false);
        }

        if (position.length == 1) {
            position.splice(0, 0, false);
            position.splice(2, 0, false);
        }
    });

    return updatedSubPanels;
};

export const previewSubpanels = (subPanels_, vNum, hNum) => {
    const subPanels = JSON.parse(JSON.stringify(subPanels_));
    const preview = [];
    subPanels.forEach((subPanel, j) => {
        if ((hNum < 1 && (j == 0 || j == 2)) || (hNum == 1 && j == 1)) {
            return;
        }

        const subPanel_ = [];

        subPanel.forEach((panel, i) => {
            if ((vNum < 1 && (i == 0 || i == 2)) || (vNum == 1 && i == 1)) {
                return;
            }

            subPanel_.push(panel);
        });

        preview.push(subPanel_);
    });

    return preview;
};

export const getDoorInfo = (config) => {
    const hasDoor = {
        [MATERIAL_TYPE.EXTERIOR_COLOUR]: false,
        [MATERIAL_TYPE.CARCASE_COLOUR]: false,
    };

    if (config.hasOwnProperty('structure')) {
        config.structure.forEach((structure) => {
            if (structure.name === 'Materials' && structure.fieldsets) {
                structure.fieldsets.forEach((fieldset) => {
                    if (fieldset.name === 'materials' && fieldset.fields) {
                        fieldset.fields.forEach((field) => {
                            if (
                                field.hasOwnProperty('options') &&
                                field.options.hasOwnProperty('hasDoorStyle') &&
                                field.name === 'cabinet_ext_colour'
                            ) {
                                hasDoor[MATERIAL_TYPE.EXTERIOR_COLOUR] =
                                    field.options.hasDoorStyle;
                            }

                            if (
                                field.hasOwnProperty('options') &&
                                field.options.hasOwnProperty('hasDoorStyle') &&
                                field.name === 'cabinet_carc_colour'
                            ) {
                                hasDoor[MATERIAL_TYPE.CARCASE_COLOUR] =
                                    field.options.hasDoorStyle;
                            }
                        });
                    }
                });
            }
        });
    }

    return hasDoor;
};

export const useComponentMountedHelper = () => {
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        };
    }, []);

    return {
        isMounted,
    };
};

export const genericReducer = (state, action) => {
    if (!action.hasOwnProperty('type') && action.hasOwnProperty('payload')) {
        const {key, value} = action.payload;

        const updatedState = cloneDeep(state);

        updatedState[key] = value;

        return updatedState;
    } else if (
        action.hasOwnProperty('type') &&
        action.hasOwnProperty('payload')
    ) {
        switch (action.type) {
            case 'merge_update':
                return mergeWith(
                    cloneDeep(state),
                    action.payload,
                    (objValue, srcValue) => {
                        if (
                            Array.isArray(objValue) &&
                            Array.isArray(srcValue)
                        ) {
                            return srcValue;
                        }
                    }
                );
            default:
                return state;
        }
    } else return state;
};

export const getCabinetFields = (structures, checkType = false, fieldName) => {
    const fields = {};
    let hasExterior = false;
    let hasCarcase = false;
    let hasExteriorDoor = false;
    let hasCarcaseDoor = false;
    let adjacentFields = [];
    let adjacentFieldsRestrictToOne = [];

    structures.forEach((structure) => {
        if (structure.hasOwnProperty('fieldsets')) {
            structure.fieldsets.forEach((fieldset) => {
                if (fieldset.hasOwnProperty('fields')) {
                    if (
                        (fieldset.name == 'cabinet_edges' ||
                            fieldset.name == 'door_edges' ||
                            fieldset.name == 'drawer_panel_edges') &&
                        typeof fieldName !== 'undefined'
                    ) {
                        fieldset.fields.forEach((field) => {
                            if (
                                field.name == fieldName &&
                                field.hasOwnProperty('options')
                            ) {
                                adjacentFields = field.options.adjacentFields;
                                adjacentFieldsRestrictToOne =
                                    field.options.adjacentFieldsRestrictToOne;
                            }
                        });
                    } else {
                        fieldset.fields.forEach((field) => {
                            if (field.name === 'cabinet_carc_colour') {
                                hasCarcase = true;

                                if (
                                    field.hasOwnProperty('options') &&
                                    field.options.hasOwnProperty(
                                        'hasDoorStyle'
                                    ) &&
                                    field.options.hasDoorStyle
                                ) {
                                    hasCarcaseDoor = true;
                                }
                            }

                            if (field.name === 'cabinet_ext_colour') {
                                hasExterior = true;

                                if (
                                    field.hasOwnProperty('options') &&
                                    field.options.hasOwnProperty(
                                        'hasDoorStyle'
                                    ) &&
                                    field.options.hasDoorStyle
                                ) {
                                    hasExteriorDoor = true;
                                }
                            }

                            if (checkType) {
                                if (
                                    field.type == 'numeric' ||
                                    field.type == 'size'
                                )
                                    fields[field.name] = field.displayName
                                        ? field.displayName
                                        : '';
                            } else
                                fields[field.name] = field.displayName
                                    ? field.displayName
                                    : '';
                        });
                    }
                }
            });
        }
    });

    return {
        fields,
        hasExterior,
        hasExteriorDoor,
        hasCarcase,
        hasCarcaseDoor,
        adjacentFields,
        adjacentFieldsRestrictToOne,
    };
};

export const xmlFileReader = async (files) => {
    const invalidFiles = [];
    const contents = [];
    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.type == 'text/xml') {
            contents.push((cb) => {
                const reader = new FileReader();

                reader.readAsText(file);
                reader.onload = () => {
                    cb(null, reader.result);
                };

                reader.onerror = () => {
                    cb(reader.error.message);
                };
            });
        } else {
            invalidFiles.push(file);
        }
    }

    if (contents.length == 0) {
        if (invalidFiles.length) {
            return {
                invalidFiles,
                error: 'Incorrect file format. Please upload a KD Max .XML quotation file',
            };
        }

        return {error: 'No valid files found'};
    }

    try {
        const results = await series(contents);

        return {contents: results, error: '', invalidFiles};
    } catch (error) {
        return {error};
    }
};

export const xmlFileParser = (xmlContent: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        parseString(xmlContent, (error, result) => {
            if (error || !result) {
                reject(error);
                return;
            }

            resolve(result);
        });
    });
};

export const getHingeDirection = (structure: Object): string => {
    let hingeDirection: string = 'vertical';

    if (
        structure.hasOwnProperty('preview_options') &&
        structure.preview_options.hasOwnProperty('hingeDirection')
    ) {
        hingeDirection = structure.preview_options.hingeDirection.toLowerCase();
    }

    return hingeDirection;
};

export const formatDimensionString = (productConfig, formData) => {
    const dimensionString = [];

    productConfig.dimension.forEach((dimensions) => {
        if (dimensions[0].indexOf('height') !== -1) {
            dimensionString.push(formData.height);
        } else if (dimensions[0].indexOf('width') !== -1) {
            dimensionString.push(formData.width);
        } else if (dimensions[0].indexOf('depth') !== -1) {
            dimensionString.push(formData.depth);
        }
    });

    return dimensionString.join(' x ');
};

export const getOptions = (key: string, structures: any[]): {} | null => {
    let options;

    structures.forEach((structure) => {
        if (structure.fieldsets) {
            structure.fieldsets.forEach((fieldset) => {
                if (fieldset.name == key && fieldset.options) {
                    options = fieldset.options;
                } else {
                    if (fieldset.fields) {
                        fieldset.fields.forEach((field) => {
                            if (field.name == key && field.options) {
                                options = field.options;
                            }
                        });
                    }
                }
            });
        }
    });

    if (options && Object.keys(options).length) {
        return options;
    }

    return null;
};

export const getEdgeVisibility = (
    formData,
    materialOptions,
    values
): boolean => {
    let expression = null;
    formData.forEach((data) => {
        if (expression != null) return;
        const doorEdgeFieldSet = data.fieldsets.find((fieldset) => {
            return fieldset.name == 'door_edges';
        });
        if (
            doorEdgeFieldSet &&
            doorEdgeFieldSet.hasOwnProperty('options') &&
            doorEdgeFieldSet.options.hasOwnProperty('visible')
        ) {
            expression = doorEdgeFieldSet.options.visible;
        }
    });
    if (expression == null) return false;
    return excel.calculate(expression, {...values, ...materialOptions});
};

export const moveItemToLast = (array, handler) => {
    const arrayLocal = [...array];
    const index = arrayLocal.findIndex(handler);

    if (index !== -1) {
        const removedObject = arrayLocal.splice(index, 1)[0];
        arrayLocal.push(removedObject);
    }

    return arrayLocal;
};

/**
 *
 *
 * @param {number} size
 * @return {*}  {string}
 */
export const formatFileSize = (size: number): string => {
    return size / 1024 > 1024
        ? `${(size / (1024 * 1024)).toFixed(2)} MB`
        : `${(size / 1024).toFixed(2)} KB`;
};
